<template>
  <div
    v-loading="loading"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
    style="height: 88vh; overflow-y: hidden"
  >
    <el-table
      v-show="!loading"
      :data="table_data"
      row-key="name"
      :border="true"
      height="88vh"
      :header-cell-style="set_header_cell_style"
      :cell-style="set_cell_style"
      :expand-row-keys="['NODE']"
      style="background-color: transparent"
    >
      <el-table-column prop="description" width="200" fixed="left">
      </el-table-column>
      <el-table-column
        v-for="item in job_arr"
        :key="item.jobId"
        align="center"
        header-align="center"
        min-width="200"
      >
        <template slot="header" slot-scope="scope">
          <div v-show="!item.isEditName">
            <span slot="reference" @dblclick="start_edit_job_name(item.jobId)"
              >&nbsp;{{ item.name }}</span
            >
            <el-dropdown>
              <span>
                <i
                  style="margin-left: 5px; color: rgb(48, 100, 143)"
                  class="iconfont icon-a-216gengduo-shuxiang"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="delete_job(item.jobId)">
                  <i
                    style="margin-right: 5px"
                    class="iconfont icon-temp-delete"
                  />
                  {{ $t("btn.deleteBtn") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <input
            :id="'jobEditorElement_zdh' + item.jobId"
            v-show="item.isEditName"
            v-model="item.newName"
            class="add-new-job-input"
            type="text"
            :placeholder="$t('permission.placeholder')"
            @blur="end_edit_job_name(item.jobId)"
            @keyup.enter="end_edit_job_name(item.jobId)"
          />
        </template>
        <template slot-scope="scope">
          <el-tooltip
            :disabled="
              !can_change(item.jobId, item.name, scope.row.jobIds, scope.row)
            "
            class="item"
            effect="dark"
            :content="$t('permission.message.error')"
          >
            <el-checkbox
              v-if="scope.row.jobIds"
              v-model="scope.row.jobIds"
              :disabled="
                can_change(item.jobId, item.name, scope.row.jobIds, scope.row)
              "
              :label="item.jobId"
              @change="
                update_job_authorities(
                  item.jobId,
                  item.name,
                  scope.row.jobIds,
                  scope.row
                )
              "
            >
            </el-checkbox>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        fixed="right"
        width="180"
      >
        <template slot="header" slot-scope="scope">
          <button
            v-if="!is_add_new_job"
            class="add-new-job-btn"
            @click="add_new_job_click"
          >
            <i class="iconfont icon-add_role" style="margin-right: 5px" />
            {{ $t("permission.addNewRoleBtn") }}
          </button>
          <input
            v-else
            v-model="new_job_name"
            class="add-new-job-input"
            type="text"
            :placeholder="$t('permission.placeholder')"
            @blur="add_new_job_confirm"
            @keyup.enter="add_new_job_confirm"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  get_jobAuthorities,
  update_jobAuthorities,
  add_new_job,
  edit_job_name,
  delete_job,
  get_authorities,
  get_authority_types,
} from "@/network/home/index.js";
import Vue from "vue";

export default {
  inject: ["reload"],
  name: "HomePermissionVue",
  data() {
    return {
      activeIndex: "/home/permission",
      // 页面数据是否在加载
      loading: true,
      // 职位列表
      job_arr: [],
      // 转换后的权限列表——用于表格展示
      table_data: [],
      is_add_new_job: false,
      new_job_name: "",
    };
  },
  watch: {
    "$route.params.projectId": {
      handler: function () {
        this.get_data();
      },
    },
  },
  created() {
    this.get_data();
  },
  methods: {
    // 设置表头行样式
    set_header_cell_style() {
      return "font-size:16px; color: rgba(112, 112, 112, 1); font-weight:normal";
    },

    // 设置单元格样式
    set_cell_style({ row }) {
      let style = "color:rgba(84, 85, 86, 1);";
      if (row.children) {
        style += " font-size:16px; border-right:none;";
      } else {
        style += " font-size:14px;";
      }
      if (
        this.table_data.findIndex((item) => {
          return item.name == row.name || item.name == row.type;
        }) %
          2 ==
        0
      ) {
        style += " background-color: rgba(250, 250, 250, 1);";
      }

      return style;
    },

    // 获得并组合当前职位权限数据
    get_data() {
      const map = [
        this.$t("user.userTable.positionType.pm"),
        this.$t("user.userTable.positionType.requirement"),
        this.$t("user.userTable.positionType.developer"),
        this.$t("user.userTable.positionType.test"),
        this.$t("user.userTable.positionType.operation"),
      ];
      this.loading = true;
      this.job_arr = [];
      get_jobAuthorities(this.get_pid()).then((res) => {
        res.forEach((item) => {
          if (map.indexOf(item.name) !== -1) {
            this.job_arr[map.indexOf(item.name)] = item;
          } else {
            this.job_arr.push(item);
          }
        });
        this.job_arr.forEach((item) => {
          // 响应式添加属性
          Vue.set(item, "isEditName", false);
          Vue.set(item, "newName", item.name);
        });
        this.job_arr = this.job_arr.filter((item) => {
          return item;
        });
        get_authority_types().then((res) => {
          this.table_data = res;
          this.table_data.forEach((item) => {
            Vue.set(item, "children", []);
          });
          get_authorities().then((res) => {
            res.forEach((authorityItem) => {
              Vue.set(authorityItem, "jobIds", []);
              this.job_arr.forEach((jobItem) => {
                if (
                  jobItem.jobAuthorities.findIndex((item) => {
                    return item == authorityItem.name;
                  }) > -1
                ) {
                  authorityItem.jobIds.push(jobItem.jobId);
                }
              });
              const target_type = this.table_data.find((item) => {
                return item.name == authorityItem.type;
              });
              target_type.children.push(authorityItem);
            });
            this.loading = false;
          });
        });
      });
    },

    // 点击添加新职位
    add_new_job_click() {
      this.is_add_new_job = true;
    },

    // 添加新职位失去焦点，与后端通信
    add_new_job_confirm() {
      if (this.new_job_name) {
        add_new_job(this.get_pid(), this.new_job_name, []).then((res) => {
          this.get_data();
        });
        this.new_job_name = "";
      }
      this.is_add_new_job = false;
    },

    // 双击重命名职位名称
    start_edit_job_name(jobId) {
      this.job_arr.find((item) => {
        return item.jobId == jobId;
      }).isEditName = true;
      this.$nextTick(() => {
        document.getElementById("jobEditorElement_zdh" + jobId).focus();
      });
    },

    // 重命名失去焦点, 重命名完成
    end_edit_job_name(jobId) {
      const target_job = this.job_arr.find((item) => {
        return item.jobId == jobId;
      });
      if (target_job.name !== target_job.newName && target_job.newName !== "") {
        target_job.name = target_job.newName;
        edit_job_name(this.get_pid(), target_job.jobId, target_job.name);
      } else if (target_job.newName === "") {
        target_job.newName = target_job.name;
      }
      target_job.isEditName = false;
    },
    // 判断复选框是否可以选中
    can_change(jobId, name, jobIds, row) {
      let flag = false;
      this.table_data.forEach((table_item) => {
        table_item.children.forEach((table_child) => {
          if (
            table_child.dependencies.indexOf(row.name) !== -1 &&
            table_child.jobIds.indexOf(jobId) !== -1
          ) {
            flag = true;
          }
        });
      });
      return flag;
    },
    // 修改当前职位权限
    update_job_authorities(jobId, name, jobIds, row) {
      row.dependencies.forEach((dep) => {
        this.table_data.forEach((table_item) => {
          table_item.children.forEach((table_child) => {
            if (
              dep === table_child.name &&
              table_child.jobIds.indexOf(jobId) === -1
            ) {
              table_child.jobIds.push(jobId);
            }
          });
        });
      });
      const jobAuthorities = [];
      this.table_data.forEach((tableItem) => {
        tableItem.children.forEach((authorityItem) => {
          if (
            authorityItem.jobIds.findIndex((item) => {
              return item == jobId;
            }) > -1
          ) {
            jobAuthorities.push(authorityItem.name);
          }
        });
      });
      update_jobAuthorities(this.get_pid(), jobId, name, jobAuthorities).then(
        () => {
          this.$store.dispatch("jobAuthorities", this.get_pid());
        }
      );
    },

    // 删除职位
    delete_job(jobId) {
      this.$confirm(
        this.$t("permission.deleteRoleForm.desc"),
        this.$t("permission.deleteRoleForm.title"),
        {
          confirmButtonText: this.$t("btn.confirmBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          type: "warning",
        }
      )
        .then(() => {
          delete_job(this.get_pid(), jobId).then((res) => {
            this.$message({
              type: "success",
              message: this.$t("fileType.message.deleteSuccess"),
            });
            this.get_data();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("fileType.message.deleteInfo"),
          });
        });
    },
  },
};
</script>

<style scoped>
.el-checkbox >>> .el-checkbox__inner {
  width: 20px;
  height: 20px;
}

.el-checkbox >>> .el-checkbox__inner:hover {
  border-color: #30648f;
}

.el-checkbox >>> .el-checkbox__inner::after {
  border: 2px solid white;
  border-left: 0px;
  border-top: 0px;
  height: 13px;
  left: 5px;
  position: absolute;
  top: 0px;
  width: 6px;
}

.el-checkbox >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #30648f;
  border-color: #30648f;
}

.el-checkbox >>> .el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: #f2f6fc;
}

.el-checkbox >>> .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #30648f;
}

.el-checkbox >>> .el-checkbox__label {
  display: none;
}

.add-new-job-btn {
  width: 100%;
  height: 29px;
  border: 1px solid rgba(51, 102, 144, 1);
  border-radius: 5px;
  color: rgba(51, 102, 144, 1);
  background-color: #fff;
}

.add-new-job-btn:hover {
  cursor: pointer;
}

.add-new-job-input {
  width: 109px;
  height: 29px;
  border: 1px solid rgba(166, 166, 166, 1);
  border-radius: 4px;
  outline: none;
  padding-left: 10px;
}
</style>
